//import material-ui
import DoneIcon from "@mui/icons-material/Done";
// import ButtonTable from "../../Buttons/buttonTable";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableSortLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import { FarmService } from "../../../service/farm_service";
import { ManagementService } from "../../../service/management_service";
import { PropertyInputService } from "../../../service/property_input_service";
import { captalizeFirstLetterEacheWord, getDateWithoutTimezone } from "../../../utils/utils";
import styles from "./TableManagDemand.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};
function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort,columns} =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	
	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
					
				))}
				<TableCell></TableCell>
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};
const columns = [
	{
		id:"servico",
		label:"Serviço",
	},
	{
		id: "insumo",
		label: "Insumo",
	},
	{
		id:"data",
		label: "Data",
	},
	{
		id:"safra",
		label:"Safra",
	},
	
];

function TableManagDemand(props) {
	const [search, setSearch] = useState("");
	const [page, setPage] = React.useState(0); //page tabela
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //rows per page
	const [open, setOpen] = React.useState(false);
	const [indexDialog, setIndexDialog] = useState("");
	const [allManejos, setAllManejos] = React.useState([]);
	const keys = columns; //array de chaves
	const [selected, setSelected] = useState([]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState(keys[0].id);

	const managementService = new ManagementService();
	const farmService = new FarmService();
	const propertyInputService = new PropertyInputService();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = dataFilter.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	useEffect(() => setSearch(props.search), [props]);

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const user = localStorage.getItem("user");
		
		let filter = { pendente: true };
		if(!user.admin) filter.responsavel = user.id;

		const params = {
			page: 1,
			limit: 100000,
			filterValue: encodeURI(JSON.stringify(filter))
		};

		const managements = await managementService.getByProperty(propertyId, params);
		setAllManejos(await getManejoDetails(managements));
	};

	const getManejoDetails = async (manejos) => {
		const manejosWithDetails = [];
		for (let i = 0; i < manejos.length; i++) {
			const manejo = manejos[i];
			const safra = manejo.safra;
			const talhao = safra.talhao;
			const farm = await farmService.getById(safra.propriedadeCultivoId);
			const cultivation = farm.cultivo;
			let produtos = "";
			let quantity = "";
			const propertyInputs = manejo.propriedadeInsumos;
			for (let j = 0; j < propertyInputs.length; j++) {
				const propertyInput = await propertyInputService.getById(propertyInputs[j].propriedadeInsumoId);
				const insumo = propertyInput.insumoEmGrupo.insumo;
				produtos += insumo.nome + (j < propertyInputs.length - 1 ? ", " : "");
				quantity +=
					propertyInput.quantidade + (j < propertyInputs.length - 1 ? ", " : "");
			}
			if (manejo.quantidadeVolumeCalda)
				quantity += `/ ${manejo.quantidadeVolumeCalda}`;
			const propertyServico = manejo.propriedadeServico;
			const servico = propertyServico ? propertyServico.servico : null;
			const date = getDateWithoutTimezone(manejo.dataAplicacao);
			const subDiv = safra?.subdivisao;
			const catSub = subDiv?.categoriaSubdivisao;
			const dateString =
				("0" + date.getUTCDate()).slice(-2) +
				"/" +
				("0" + (date.getUTCMonth() + 1)).slice(-2) +
				"/" +
				date.getUTCFullYear(); 
			let newManejo = {
				id: manejo.id,
				itemId: manejo.id,
				safra: `Talhão ${talhao.nome} ${
					subDiv ? captalizeFirstLetterEacheWord(catSub) + " " + subDiv?.complemento : ""
				} - ${captalizeFirstLetterEacheWord(cultivation.cultura)} ${captalizeFirstLetterEacheWord(cultivation.variedade ?? "")}`,
				insumo: produtos !== "" ? produtos : "Não aplicado",
				servico: servico ? servico.nome : "Não aplicado",
				quantidade: quantity !== "" ? quantity : "Não se aplica",
				data: dateString,
			};
			manejosWithDetails.push(newManejo);
		}
		return manejosWithDetails;
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //seta a pagina atual tabela
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //seta a quantidade de linhas por pagina
		setPage(0); //seta a pagina atual tabela
	};

	const dataFilter = allManejos.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);

	const handleClickOpen = (index) => {
		setOpen(true);
		setIndexDialog(index);
	};
	const handleClose = () => {
		setIndexDialog("");
		setOpen(false);
	};
	const handleCloseAndAdd = (index) => {
		setOpen(false);
		handleRemoveItem(index);
	};
	const handleRemoveItem = (index) => {
		const manejoId = visibleRows[index].itemId;
		window.location.href = `/manejo/cadastroManejo?confirm=s&id=${manejoId}`;
		// const newdata = data.filter((item, itemIndex) => itemIndex !== index);
		// setData(newdata);
	};
	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};
	function descendingComparator(a, b, orderBy) {
		if (orderBy === "data") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}
		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(dataFilter, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage, dataFilter]
	);
	return (
		<div>
			<Paper>
				<TableContainer>
					<Table stickyHeader>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClicks}
							onRequestSort={handleRequestSort}
							rowCount={dataFilter.length}
							columns={keys}
						/>
						<TableBody>
							{
								//cria as linhas da tabela
								visibleRows
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										return (
											<TableRow
												hover
												tabIndex={-1}
												key={row.id}
												sx={{ height: 3 }}
											>
												{keys.map((column) => {
													//seta as colunas
													return (
														<TableCell id={row.id} key={column}>
															{row[column.id]}
														</TableCell>
													);
												})}
												<TableCell>
													<button
														className={styles.ActivitiesProductBtn}
														onClick={() => handleClickOpen(index)}
													>
														<DoneIcon />
													</button>
												</TableCell>

												<TableCell></TableCell>
											</TableRow>
										);
									})
							}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
					component="div"
					colSpan={7}
					count={visibleRows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					labelRowsPerPage="Linhas por pagina"
					SelectProps={{
						inputProps: {
							"aria-label": "rows per page",
						},
						native: false,
					}}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					ActionsComponent={TablePaginationActions}
				/>
			</Paper>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				{/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Deseja finalizar essa atividade?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<button className={styles.ButtonCancelarModal} onClick={handleClose}>
						Cancelar
					</button>
					<button
						className={styles.ButtonColherModal}
						onClick={() => handleCloseAndAdd(indexDialog)}
					>
						Finalizar
					</button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default TableManagDemand;

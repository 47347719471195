import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import InputMask from "../../../components/InputMask";
import RegisterArea from "../../../components/RegistrationArea";
import TopMenu from "../../../components/TopMenu";
import styles from "./ClientCad.module.css";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	m: 0,
	p: 0,
};

import { Typography } from "@mui/material";
import "./style.css";
import { PropertyService } from "../../../service/property_service";
import { CustomerService } from "../../../service/customer_service";

function ClientCad() {
	const [customer, setCustomer] = useState({endereco: {}});
	const [searchParams] = useSearchParams();
	const [id, setId] = useState(null);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [propriedades, setPropriedades] = useState([]);
	const [errorMessageBairro, setErrorMessageBairro] = useState(false);
	const [errorMessageCidade, setErrorMessageCidade] = useState(false);
	const [errorMessageEndereco, setErrorMessageEndereco] = useState(false);
	const [errorMessageCep, setErrorMessageCep] = useState(false);
	const [errorMessageNome, setErrorMessageNome] = useState(false);
	const [errorMessageEstado, setErrorMessageEstado] = useState(false);
	const [errorMessageCpfCnpj, setErrorMessageCpfCnpj] = useState(false);

	const propertyService = new PropertyService();
	const customerService = new CustomerService();

	const enderecoRef = useRef(null);

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const customerId = searchParams.get("id");
		if (!customerId) {
			const businessProperties = await getPropertiesForBusiness();
			setPropriedades(businessProperties);
		} else {
			setId(customerId);

			const customerById = await customerService.getById(customerId);
			customerById.telefone = customerById.telefone?.replace("+55", "");
			customerById.endereco = customerById.enderecoCliente;
			setCustomer(customerById);

			const properties = await getPropertiesForCustomer(customerById);
			setPropriedades(properties);
		}
	};

	const getPropertiesForBusiness = async () => {
		const localUser = JSON.parse(localStorage.getItem("user"));
		const businessProperty = await propertyService.getByBusiness(localUser.businessId);
		const properties = businessProperty.map((property, i) => {
			return {
				id: i,
				propertyId: property.id,
				nome: property.nome,
				selected: false,
				changed: false,
			};
		});
		return properties;
	};

	const getPropertiesForCustomer = async (customer) => {
		const properties = [];
		const localUser = JSON.parse(localStorage.getItem("user"));
		const businessProperty = await propertyService.getByBusiness(localUser.businessId);

		for (let i = 0; i < businessProperty.length; i++) {
			const property = businessProperty[i];
			const customerPropertyIndex = customer.propriedadeClientes.findIndex((prop) => prop.propriedade.id === property.id);
			properties.push({
				id: i,
				propertyId: property.id,
				nome: property.nome,
				selected: customerPropertyIndex >= 0,
				changed: false,
			});
		}
		return properties;
	};

	const validationSave = async () => {
		if (
			customer.endereco.bairro &&
			customer.endereco.bairro !== "" &&
			customer.endereco.cidade &&
			customer.endereco.cidade !== "" &&
			customer.endereco.cep &&
			customer.endereco.cep !== "" &&
			enderecoRef.current.value &&
			enderecoRef.current.value !== "" &&
			customer.nome &&
			customer.nome !== "" &&
			customer.endereco.uf &&
			customer.endereco.uf !== "0" &&
			customer.cpf_cnpj &&
			(customer.cpf_cnpj.length === 11 || customer.cpf_cnpj.length === 14)
		) {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			return true;
		} else {
			let message = "Um ou mais campos vazios! - (";
			if (!customer.nome || customer.nome === "") {
				message += "Nome/Razao, ";
				setErrorMessageNome(true);
			} else {
				setErrorMessageNome(false);
			}
			if (!customer.cpf_cnpj || customer.cpf_cnpj.length !== 11 && customer.cpf_cnpj.length !== 14) {
				message += "CPF/CNPJ, ";
				setErrorMessageCpfCnpj(true);
			} else {
				setErrorMessageCpfCnpj(false);
			}
			if (!customer.endereco.cep || customer.endereco.cep === "") {
				message += "CEP, ";
				setErrorMessageCep(true);
			} else {
				setErrorMessageCep(false);
			}
			if (!customer.endereco.cidade || customer.endereco.cidade === "") {
				message += "Cidade, ";
				setErrorMessageCidade(true);
			} else {
				setErrorMessageCidade(false);
			}
			if (!customer.endereco.uf || customer.endereco.uf === "0") {
				message += "Estado, ";
				setErrorMessageEstado(true);
			} else {
				setErrorMessageEstado(false);
			}
			if (!customer.endereco.bairro || customer.endereco.bairro === "") {
				message += "Bairro, ";
				setErrorMessageBairro(true);
			} else {
				setErrorMessageBairro(false);
			}
			if (!enderecoRef.current.value || enderecoRef.current.value === "") {
				message += "Endereço, ";
				setErrorMessageEndereco(true);
			} else {
				setErrorMessageEndereco(false);
			}

			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};

	const handleSave = async () => {
		const propertiesToSave = propriedades.filter((prop) => prop.changed && prop.selected);
		const propertiesToSaveId = propertiesToSave.map((prop) => prop.propertyId);

		let customerToSave = {
			name: customer.nome,
			cpf_cnpj: customer.cpf_cnpj,
			fantasyName: customer.nomeFantasia,
			phone: customer.telefone ? `+55${customer.telefone}` : null,
			email: customer.email,
			neighborhood: customer.endereco.bairro,
			cep: customer.endereco.cep,
			city: customer.endereco.cidade,
			federativeUnit: customer.endereco.uf,
			address: enderecoRef.current.value,
			observation: customer.endereco.observacao,
			includeProperties: propertiesToSaveId,
		};
		if (!id) {
			await customerService.save(customerToSave);
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
		} else {
			const propertiesToRemove = propriedades.filter((prop) => prop.changed && !prop.selected);
			const propertiesToRemoveId = propertiesToRemove.map((prop) => prop.propertyId);

			customerToSave.removeClientProperties = propertiesToRemoveId;
			customerToSave.id = id;
			customerToSave.addressId = customer.endereco.id;

			await customerService.update(customerToSave);
		}
		setTypeMessage("success");
		setMessage("cadastro atualizado!!!");
	};

	function handleChangeCEP(event) {
		let cepText = event.target.value;
		if (cepText.length > 7) {
			fetch(`https://viacep.com.br/ws/${cepText}/json/`, {
				method: "GET",
			})
				.then((response) => response.json())
				.then((data) => {
					if (!("erro" in data)) {
						setCustomer({...customer, endereco: {
							...customer.endereco, 
							uf: data.uf,
							bairro: data.bairro,
							cidade: data.localidade
						}});
					}
				})
				.catch(() => {
					setCustomer({...customer, endereco: {
						...customer.endereco, 
						uf: "0",
					}});
				});
		}
	}
	const handlePropertyChangeAll = (e) => {
		const { name, checked } = e.target;
		if (name === "allSelect") {
			let tempPropriedade = propriedades.map((propriedades) => {
				let propried = propriedades;
				propried.selected = checked;
				propried.changed = checked;
				return propried;
			});
			setPropriedades(tempPropriedade);
		}
	};
	const handlePropertyChange = (item) => {
		const newPropriedades = propriedades.slice();
		const property = propriedades[item.id];
		property.selected = !property.selected;
		property.changed = !property.changed;
		newPropriedades[item.id] = property;
		setPropriedades(newPropriedades);
	};

	return (
		<div>
			<TopMenu
				open={["register", "client"]}
				help={id ? "clientEdit" : "clientCad"}
			/>
			<Box component="main" sx={{ p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={3}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item>
								<RegisterArea
									title={
										id
											? "Editar cliente"
											: "Cadastro de cliente"
									}
									href="/clientes"
									typeMessage={typeMessage}
									message={message}
									salveFunc={validationSave}
								>
									{/* inputs da pagina de resgitro */}
									<Grid
										container
										rowSpacing={3}
										columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									>
										<Grid item xs={12} sm={12} md={6}>
											<Item sx={stylesItem}>
												<div
													className={
														errorMessageNome
															? styles.errControl
															: styles.noErrControl
													}
												>	<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(customer.nome && customer.nome !== "" ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
															Nome/Razao
														</Typography>
														<p
															className={
																errorMessageNome
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>
														<input
															type="text"
															placeholder="Nome/Razao"
															id="nome"
															className={styles.testInput}
															value={customer.nome}
															onChange={(e) => setCustomer({...customer, nome: e.target.value})}
														/>
													</Box>
												</div>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={6}>
											<Item sx={stylesItem}>
												<div
													className={
														errorMessageCpfCnpj
															? styles.errControl
															: styles.noErrControl
													}
												>
													<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(customer.cpf_cnpj === "" ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
															CPF/CNPJ
														</Typography>
														<p
															className={
																errorMessageCpfCnpj
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>
														<InputMask
															placeholder="CPF/CNPJ"
															name="CPF/CNPJ"
															class={styles.testInput}
															mask={
																customer.cpf_cnpj && customer.cpf_cnpj.length > 11
																	? "99.999.999/9999-99"
																	: "999.999.999-999"
															}
															value={customer.cpf_cnpj}
															onChange={(e) => setCustomer({...customer, cpf_cnpj: e.target.value})}
														/>
													</Box>
												</div>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography 
														variant="caption"
														display="block" 
														gutterBottom 
														sx={{
															top:-11,
															position:"absolute",
															px:1,
															backgroundColor:"white",
															visibility:(customer.nomeFantasia && customer.nomeFantasia !== "" ? "visible" : "hidden"),
															transition:"all 0.5s ease"
														}} 
													>
														Nome Fantasia/Apelido
													</Typography>
													<input
														type="text"
														placeholder="Nome Fantasia/Apelido"
														id="nomeFantasia"
														className={styles.testInput}
														value={customer.nomeFantasia}
														onChange={(e) => setCustomer({...customer, nomeFantasia: e.target.value})}
													/>
												</Box>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={6}>
											<Item sx={stylesItem}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography 
														variant="caption" 
														display="block" 
														gutterBottom 
														sx={{
															top:-11,
															position:"absolute",
															px:1,
															backgroundColor:"white",
															visibility:(customer.email && customer.email !== "" ? "visible" : "hidden"),
															transition:"all 0.5s ease"
														}} 
													>
														E-mail
													</Typography>
													<input 
														type="email" 
														placeholder="E-mail" 
														id="email" 
														className={styles.testInput} 
														value={customer.email} 
														onChange={(e) => setCustomer({...customer, email: e.target.value})}
													/>
												</Box>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={6}>
											<Item sx={stylesItem}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography 
														variant="caption" 
														display="block" 
														gutterBottom 
														sx={{
															top:-11,
															position:"absolute",
															px:1,
															backgroundColor:"white",
															visibility:(customer.telefone && customer.telefone !== "" ? "visible" : "hidden"),
															transition:"all 0.5s ease"
														}}
													>
														Telefone
													</Typography>
													<InputMask
														placeholder="Telefone"
														class={styles.testInput}
														mask="(99)99999-9999"
														value={customer.telefone}
														onChange={(e) => setCustomer({...customer, telefone: e.target.value})}
													/>
												</Box>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={stylesItem}>
												<div className={styles.registrationTitle}>
													<p>Preencha os dados de localização</p>
												</div>
											</Item>
										</Grid>
										<Grid item xs={12} sm={6} md={3}>
											<Item sx={stylesItem}>
												<div
													className={
														errorMessageCep
															? styles.errControl
															: styles.noErrControl
													}
												>
													<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(customer.endereco.cep && customer.endereco.cep !== "" ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
															CEP
														</Typography>
														<p
															className={
																errorMessageCep
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>
														<input
															type="text"
															id="cep"
															placeholder="CEP"
															className={styles.testInput}
															onKeyUp={(e) => handleChangeCEP(e)}
															value={customer.endereco.cep}
															onChange={(e) => setCustomer({...customer, endereco: {...customer.endereco, cep: e.target.value}})}
														/>
													</Box>
												</div>
											</Item>
										</Grid>
										<Grid item xs={12} sm={6} md={3}>
											<Item sx={stylesItem}>
												<div
													className={
														errorMessageEstado
															? styles.errControl
															: styles.noErrControl
													}
												>
													<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(customer.endereco.uf && customer.endereco.uf !== "0" ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
															Estado
														</Typography>
														<p
															className={
																errorMessageEstado
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>
														<select
															id="estado"
															onChange={(e) => setCustomer({...customer, endereco: {...customer.endereco, uf: e.target.value}})}
															value={customer.endereco.uf}
															className={styles.testInput}
														>
															<option value={"0"}>
																{customer.endereco.uf && customer.endereco.uf !== "0"
																	? "---------------"
																	: "Estado"}
															</option>
															<option value="AC">Acre</option>
															<option value="AL">Alagoas</option>
															<option value="AP">Amapá</option>
															<option value="AM">Amazonas</option>
															<option value="BA">Bahia</option>
															<option value="CE">Ceará</option>
															<option value="DF">Distrito Federal</option>
															<option value="ES">Espirito Santo</option>
															<option value="GO">Goiás</option>
															<option value="MA">Maranhão</option>
															<option value="MS">Mato Grosso do Sul</option>
															<option value="MT">Mato Grosso</option>
															<option value="MG">Minas Gerais</option>
															<option value="PA">Pará</option>
															<option value="PB">Paraíba</option>
															<option value="PR">Paraná</option>
															<option value="PE">Pernambuco</option>
															<option value="PI">Piauí</option>
															<option value="RJ">Rio de Janeiro</option>
															<option value="RN">Rio Grande do Norte</option>
															<option value="RS">Rio Grande do Sul</option>
															<option value="RO">Rondônia</option>
															<option value="RR">Roraima</option>
															<option value="SC">Santa Catarina</option>
															<option value="SP">São Paulo</option>
															<option value="SE">Sergipe</option>
															<option value="TO">Tocantins</option>
														</select>
													</Box>
												</div>
											</Item>
										</Grid>
										<Grid item xs={12} sm={6} md={3}>
											<Item sx={stylesItem}>
												<div
													className={
														errorMessageCidade
															? styles.errControl
															: styles.noErrControl
													}
												>
													<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(customer.endereco.cidade && customer.endereco.cidade !== "" ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}}
														>
															Cidade
														</Typography>
														<p
															className={
																errorMessageCidade
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>
														<input 
															type="text" 
															placeholder="Cidade" 
															id="cidade" 
															className={styles.testInput} 
															value={customer.endereco.cidade} 
															onChange={e=> setCustomer({...customer, endereco: {...customer.endereco, cidade: e.target.value}})}
														/>
													</Box>
												</div>
											</Item>
										</Grid>
										<Grid item xs={12} sm={6} md={3}>
											<Item sx={stylesItem}>
												<div
													className={
														errorMessageBairro
															? styles.errControl
															: styles.noErrControl
													}
												><Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(customer.endereco.bairro && customer.endereco.bairro !== "" ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}}
														>
															Bairro
														</Typography>
														<p
															className={
																errorMessageBairro
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>
														<input 
															type="text" 
															placeholder="Bairro" 
															id="bairro" 
															className={styles.testInput} 
															value={customer.endereco.bairro} 
															onChange={e => setCustomer({...customer, endereco: {...customer.endereco, bairro: e.target.value}})}
														/>
													</Box>
												</div>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={stylesItem}>
												<div
													className={
														errorMessageEndereco
															? styles.errControl
															: styles.noErrControl
													}
												><Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(customer.endereco.endereco && customer.endereco.endereco !== "" ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}}
														>
															Endereço
														</Typography>
														<p
															className={
																errorMessageEndereco
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>
														<input
															type="text"
															placeholder="Endereço"
															id="endereco"
															className={styles.testInput}
															value={customer.endereco.endereco}
															ref={enderecoRef}
															onChange={e => setCustomer({...customer, endereco: {...customer.endereco, endereco: e.target.value}})}
														/>
													</Box>
												</div>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={stylesItem}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography 
														variant="caption" 
														display="block" 
														gutterBottom 
														sx={{
															top:-11,
															position:"absolute",
															px:1,
															backgroundColor:"white",
															visibility:(customer.endereco.observacao && customer.endereco.observacao !== ""? "visible" : "hidden"),
															transition:"all 0.5s ease"
														}} 
													>
														Observações
													</Typography>
													<input
														type="text"
														id="observacao"
														placeholder="Observações"
														className={styles.testInput}
														value={customer.endereco.observacao}
														onChange={e => setCustomer({...customer, endereco: {...customer.endereco, observacao: e.target.value}})}
													/>
												</Box>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<Item
												sx={{
													// border: "1px solid #b1b1b1",
													boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
													display: "flex",
													flexDirection: "column",
													alignItems: "flex-start",
													height: "100%",
												}}
											>
												<p className={styles.labelTitle}>
													<b>Propriedades</b>
												</p>
												<div className={styles.checkboxContainer}>
													<input
														type="checkbox"
														className={styles.inputCheckbox}
														name="allSelect"
														id="propriedade"
														checked={
															!propriedades.some(
																(propriedade) => propriedade.selected !== true
															)
														}
														onChange={(e) => handlePropertyChangeAll(e)}
													/>
													<label
														htmlFor="propriedade"
														className={styles.labelTitle}
													>
														<b>
															Cadastre o Cliente simultaneamente na suas outras
															propriedades
														</b>
													</label>
												</div>
												<Grid
													container
													rowSpacing={3}
													columnSpacing={{ xs: 1, sm: 2, md: 3 }}
												>
													{propriedades.map((propriedade, index) => (
														<Grid
															item
															xs={"auto"}
															key={index}
															sx={{ minWidth: "250px" }}
														>
															<Item
																htmlFor={propriedade.id}
																sx={{
																	display: "flex",
																	justifyContent: "start",
																	p: 0,
																	m: 0,
																}}
															>
																<div className={styles.checkboxContainer}>
																	<input
																		type="checkbox"
																		className={styles.inputCheckbox}
																		id={propriedade.id}
																		name={propriedade.nome}
																		checked={propriedade.selected}
																		onChange={() =>
																			handlePropertyChange(propriedade)
																		}
																	/>
																	<label
																		htmlFor={propriedade.id}
																		className={styles.CheckPropriedadeLabel}
																	>
																		{propriedade.nome}
																	</label>
																</div>
															</Item>
														</Grid>
													))}
												</Grid>
											</Item>
										</Grid>
									</Grid>
								</RegisterArea>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}
export default ClientCad;

import React, { useEffect, useState } from "react";
import InputSearch from "../../components/SearchHeader/inputSeach";
import TopMenu from "../../components/TopMenu";
import "../CommonStyle/style.css";
import styles from "./Insumos.module.css";

//import components
import * as Btn from "../../components/Buttons";
import { IconIosShare } from "../../components/IconsImport";
import Search from "../../components/SearchHeader";
import Table from "../../components/TableGenerator";
//material ul imports
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { PropertyInputService } from "../../service/property_input_service";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
//column
const columns = [
	{
		id: "nome",
		label: "nome",
	},
	{
		id: "unidadeMedida",
		label: "Unidade",
	},
	{
		id: "grupo",
		label: "Grupo",
	},
]; 

function Insumos() {
	const [search, setSearch] = useState("");
	const [insumos, setInsumos] = useState([]);

	const propertyInputService = new PropertyInputService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const inputParams = {
			page: 1,
			limit: 100000,
		};
		let inputsInProperty = await propertyInputService.get(propertyId, inputParams);

		inputsInProperty = inputsInProperty.map((inputInProperty) => {
			return {
				id: inputInProperty.id,
				nome: inputInProperty.insumoEmGrupo.insumo.nome,
				grupo: inputInProperty.insumoEmGrupo.grupoInsumo.nome,
				unidadeMedida: inputInProperty.unidadeMedida.sigla,
			};
		});
		setInsumos(inputsInProperty);
	};

	const dataFilter = insumos.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);
	function handleClick(e) {
		setSearch(e.target.value);
	}

	//check delete function
	const handleDelete = async (id) => {
		await propertyInputService.delete(id);
		initData();
	};

	const generateInputReport = () => {
		return (window.location.href = "/pdf/?pag=Insumos");
	};
	return (
		<div>
			<TopMenu open={["register", "insumos"]} help="insumos" />
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<Search
									title="Insumos"
									href="/insumos/cadastroInsumo"
									textButton="Novo Insumo"
								>
									<InputSearch func={handleClick} />
								</Search>
							</Item>
						</Grid>
						<Grid item xs={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
									p: 0,
									m: 0,
								}}
							>
								<Table
									data={dataFilter}
									columns={columns}
									handleDelete={handleDelete}
									editHref="/insumos/cadastroInsumo?id="
								>
									<Btn.GenericButtonGreen
										text="Exportar lista de produtos"
										className={styles.buttonTableUpInsumos}
										funcClick={generateInputReport}
										icon={
											<div>
												<IconIosShare />
											</div>
										}
									/>
								</Table>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default Insumos;

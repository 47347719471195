/* eslint-disable no-unused-vars */
import IosShareIcon from "@mui/icons-material/IosShare";
import React, { useEffect, useState } from "react";
import TopMenu from "../../../components/TopMenu";

//import css modules
import styles from "./StockCad.module.css";
/* import "./style.css"; */

//components
import RegisterArea from "../../../components/RegistrationArea";
//material ul imports
import Grid from "@mui/material/Grid";

import {
	AttachFile,
	Description,
	PictureAsPdf,
	Theaters,
} from "@material-ui/icons";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import { Autocomplete, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { ptBR } from "@mui/material/locale";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { DropzoneArea } from "material-ui-dropzone";
import { useParams, useSearchParams } from "react-router-dom";
import InputData from "../../../components/InputData";
import { PropertyInputService } from "../../../service/property_input_service";
import { StockService } from "../../../service/stock_service";

const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const stylesItem = {
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
};
const styleInputFile = {
	bgcolor: "#1A877F",
	display: "flex",
	flexDirection: "column",
	alignItems: "start",
	justifyContent: "start",
	borderRadius: 4,
	px: 2,
	py: 2,
	m: 0,
	color: "white",
};
function StockCad() {
	const [searchParams] = useSearchParams();
	const [allInsumos, setAllInsumos] = useState([]);
	const [valueInsumoId, setValueInsumoId] = useState(null);
	const helpIdFinter = searchParams.get("id");
	const [receituarioFile, setReceituarioFile] = useState(null);
	const [notaFiscalFile, setNotaFiscalFile] = useState(null);
	const [selectedDate, setSelectedDate] = useState("");
	const [initialReceituario, setInitialReceituario] = useState(null);
	const [initialNotaFiscal, setInitialNotaFiscal] = useState(null);
	const [observacao,setObservacao]=useState("");
	const { id } = useParams();

	const propertyInputsService = new PropertyInputService();
	const stockService = new StockService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const generalParams = {
			page: 1,
			limit: 100000,
		};

		const propertyInputs  = await propertyInputsService.get(propertyId, generalParams);
		const inputs = getInsumosForProperty(propertyInputs);
		setAllInsumos(inputs);

		if(id) {
			const entry = await stockService.getById(id);
			setSelectedDate(new Date(entry.data));
			setInputCapacidade(entry.capacidadeEmbalagem);
			setInputQuantidade(entry.quantidade);
			setObservacao(entry.observacao); 
			document.getElementById("observacao").value = entry.observacao;
			const insumosFiltered = inputs.filter((insumo) => insumo.id === entry.propriedadeinsumoID);
			if(insumosFiltered && insumosFiltered.length > 0){
				const insumo = insumosFiltered[0];
				setValueInsumoId({
					title: insumo.insumo.nome,
					id: insumo.id,
				});
			}
			await downloadFiles(id);
		}
	};

	const downloadFiles = async (entryId) => {
		await downloadInvoice(entryId);
		await downloadPrescription(entryId);
	};

	const downloadInvoice = async (entryId) => {
		const base64 = await stockService.getInvoice(entryId);

		const mimeType = getMimeType(base64);
		const binaryString = atob(base64, "base64");

		let bytes = new Uint8Array(binaryString.length);
		for (let i = 0; i < binaryString.length; i++)
			bytes[i] = binaryString.charCodeAt(i);

		const blob = new Blob([bytes], { type: mimeType });

		setInitialNotaFiscal(new File([blob], "nota_fiscal", {
			type: mimeType,
		}));
	};

	const downloadPrescription = async (entryId) => {
		const base64 = await stockService.getPrescription(entryId);

		const mimeType = getMimeType(base64);
		const binaryString = atob(base64, "base64");

		let bytes = new Uint8Array(binaryString.length);
		for (let i = 0; i < binaryString.length; i++)
			bytes[i] = binaryString.charCodeAt(i);

		const blob = new Blob([bytes], { type: mimeType });
		
		setInitialReceituario(new File([blob], "receituario", {
			type: mimeType,
		}));
	};

	const getMimeType = (base64) => {
		const types = {
			"/": "image/jpeg",
			"i": "image/png",
			"R": "image/gif",
			"U": "image/webp",
			"J": "application/pdf",
		};
		
		for (const type in types) {
			if (base64.indexOf(type) === 0)
				return types[type];
		}
	};

	const getInsumosForProperty = (propertyInputs) => {
		const insumos = [];
		for (var i = 0; i < propertyInputs.length; i++) {
			const propertyInsumo = propertyInputs[i];
			const insumoDetails = propertyInsumo.insumoEmGrupo.insumo;
			insumos.push({ id: propertyInsumo.id, insumo: insumoDetails });
		}
		return insumos;
	};

	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [inputQuantidade, setInputQuantidade] = useState("");
	const [inputCapacidade, setInputCapacidade] = useState("");
	const [errorMessageValueInsumoId, setErrorMessageValueInsumoId] =
		useState(false);
	const [errorMessageDataEntrega, setErrorMessageDataEntrega] = useState(false);
	const [errorMessageInputQuantidade, setErrorMessageInputQuantidade] =
		useState(false);
	const [errorMessageInputCapacidade, setErrorMessageInputCapacidade] =
		useState(false);
	const validationSave = async () => {
		if (
			valueInsumoId !== null &&
			selectedDate !== null &&
			inputQuantidade !== "" &&
			inputQuantidade !== "0" &&
			inputCapacidade !== "0" &&
			inputCapacidade !== ""
		) {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			return true;
		} else {
			let message = "Um ou mais campos vazios! - (";
			if (valueInsumoId === null) {
				message += "Selecione a cultura, ";
				setErrorMessageValueInsumoId(true);
			} else {
				setErrorMessageValueInsumoId(false);
			}

			if (selectedDate === null) {
				message += "Selecione a data, ";
				setErrorMessageDataEntrega(true);
			} else {
				setErrorMessageDataEntrega(false);
			}

			if (inputQuantidade === "" || inputQuantidade === "0") {
				message += "Quantidade, ";
				setErrorMessageInputQuantidade(true);
			} else {
				setErrorMessageInputQuantidade(false);
			}

			if (inputCapacidade === "" || inputCapacidade === "0") {
				message += "Operador, ";
				setErrorMessageInputCapacidade(true);
			} else {
				setErrorMessageInputCapacidade(false);
			}

			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};

	const handleSave = async () => {
		if(id) {
			const form = new FormData();
			form.append("id", id);
			form.append("packageCapacity", Number(inputCapacidade));
			form.append("date", new Date(selectedDate));
			form.append("quantity", Number(inputQuantidade));
			form.append("inputPropertyId", valueInsumoId.id);
			form.append("observation", document.getElementById("observacao").value);

			if (receituarioFile !== null && receituarioFile.length > 0) 
				form.append("prescription", receituarioFile[0]);
			
			if (notaFiscalFile !== null && notaFiscalFile.length > 0) 
				form.append("invoice", notaFiscalFile[0]);

			await stockService.update(form);
		} else {
			const form = new FormData();
			form.append("packageCapacity", Number(inputCapacidade));
			form.append("date", new Date(selectedDate));
			form.append("quantity", Number(inputQuantidade));
			form.append("inputPropertyId", valueInsumoId.id);
			form.append("observation", document.getElementById("observacao").value);

			if (receituarioFile !== null && receituarioFile.length > 0) 
				form.append("prescription", receituarioFile[0]);
			
			if (notaFiscalFile !== null && notaFiscalFile.length > 0) 
				form.append("invoice", notaFiscalFile[0]);

			await stockService.save(form);
		}
	};

	const handlePreviewIcon = (fileObject, classes) => {
		const { type } = fileObject.file;
		const iconProps = {
			className: classes.image,
		};

		if (type.startsWith("video/"))
			return <Theaters {...iconProps} sx={{ bgcolor: "white" }} />;
		if (type.startsWith("audio/"))
			return <AudiotrackIcon {...iconProps} sx={{ bgcolor: "white" }} />;

		switch (type) {
		case "application/msword":
			break;
		case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
			return <Description {...iconProps} sx={{ bgcolor: "white" }} />;

		case "application/pdf":
			return <PictureAsPdf {...iconProps} sx={{ bgcolor: "white" }} />;

		default:
			return <AttachFile {...iconProps} sx={{ bgcolor: "white" }} />;
		}
	};

	const dataAllInsumos = allInsumos.map((item, index) => {
		return {
			title: item.insumo.nome,
			id: item.id,
			index: index,
		};
	});
	const optionsAllInsumos = dataAllInsumos.map((option) => {
		const firstLetter = option.title[0].toUpperCase();
		return {
			firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
			...option,
		};
	});
	
	return (
		<div>
			<TopMenu
				open={["Query", "stock"]}
				help={helpIdFinter === null ? "stockCad" : "stockEdit"}
			/>
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
						<Grid item xs={12} sm={12} md={12} sx={stylesItem}>
							<Item>
								<RegisterArea
									title={
										helpIdFinter === null
											? "Cadastro entrada de insumos"
											: "Editar entrada de insumos no estoque"
									}
									href="/estoque"
									salveFunc={validationSave}
									typeMessage={typeMessage}
									message={message}
								>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item xs={12} sm={6} md={6} sx={{
											display: "flexbox",
											justifyContent: "center",
											alignItems: "center",
										}}>
											<div
												className={
													errorMessageValueInsumoId ? styles.errControl : styles.noErrControl
												}
											>
												<Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white", visibility:(valueInsumoId === null?"hidden":"visible"),transition:"all 0.5s ease"}} >
															Selecionar Cultura/Variedade
													</Typography>
													<p
														className={
															errorMessageValueInsumoId ? styles.errText : styles.noErrText
														}
													>
													*
													</p>
													
													<ThemeProvider theme={theme}>
														<Autocomplete
															options={optionsAllInsumos.sort(
																(a, b) => -b.firstLetter.localeCompare(a.firstLetter)
															)}
															groupBy={(option) => option.firstLetter}
															getOptionLabel={(option) => option.title}
															sx={{ width: "100%", border: "none" }}
															value={valueInsumoId === undefined ? "" : valueInsumoId}
															onChange={(event, newValue) => {
																setValueInsumoId(newValue);
															}}
															className={styles.inputText}
															renderInput={(params) => (
																<div ref={params.InputProps.ref}>																
																	<input
																		type="text"
																		{...params.inputProps}
																		placeholder="Selecionar insumo"
																		className={styles.inputText}
																	/>
																</div>
															)}
														/>
													</ThemeProvider>
												</Box>
											</div>
										</Grid>
										<Grid item xs={12} sm={6} md={6}>
											<div
												className={
													errorMessageInputCapacidade
														? styles.errControl
														: styles.noErrControl
												}
											>
												<Box
													sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
													<Typography
														variant="caption"
														display="block"
														gutterBottom
														sx={{ top: -11, position: "absolute", px: 1, backgroundColor: "white", visibility: (inputCapacidade === "" ? "hidden" : "visible"), transition: "all 0.5s ease" }} >
															Capacidade da embalagem
													</Typography>
													<p
														className={
															errorMessageInputCapacidade ? styles.errText : styles.noErrText
														}
													>
													*
													</p>
													<input
														type="number"
														placeholder="Capacidade da embalagem"
														id="capacidadeEmbalagem"
														className={styles.inputText}
														value={inputCapacidade}
														onChange={(event) => setInputCapacidade(event.target.value)}
													/>
												</Box>
											</div>
										</Grid>
										<Grid item xs={12} sm={6} md={6}>
											<div
												className={
													errorMessageDataEntrega ? styles.errControl : styles.noErrControl
												}
											>
												<Box
													sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
													<Typography
														variant="caption"
														display="block"
														gutterBottom
														sx={{ top: -11, position: "absolute", px: 1, backgroundColor: "white", visibility: (selectedDate === "" ? "hidden" : "visible"), transition: "all 0.5s ease" }} >
															Data
													</Typography>
													<p
														className={
															errorMessageDataEntrega ? styles.errText : styles.noErrText
														}
													>
													*
													</p>
													<InputData 
														placeholder="Data" 
														onDateChange={(e) => setSelectedDate(e)} 
														value={selectedDate ?
															new Date(selectedDate).toLocaleDateString("pt-BR")
															: null
														} 
														classN={styles.inputText} 
													/>
												</Box>
											</div>
										</Grid>
										<Grid item xs={12} sm={6} md={6}>
											<div
												className={
													errorMessageInputQuantidade
														? styles.errControl
														: styles.noErrControl
												}
											>
												<Box
													sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
													<Typography
														variant="caption"
														display="block"
														gutterBottom
														sx={{ top: -11, position: "absolute", px: 1, backgroundColor: "white", visibility: (inputQuantidade === "" ? "hidden" : "visible"), transition: "all 0.5s ease" }} >
															Quantidade
													</Typography>
													<p
														className={
															errorMessageInputQuantidade ? styles.errText : styles.noErrText
														}
													>
													*
													</p>
													<input
														type="number"
														placeholder="Quantidade"
														id="quantidade"
														className={styles.inputText}
														value={inputQuantidade}
														onChange={(event) => setInputQuantidade(event.target.value)}
													/>
												</Box>
											</div>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<Box
												sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
												<Typography
													variant="caption"
													display="block"
													gutterBottom
													sx={{ top: -11, position: "absolute", px: 1, backgroundColor: "white", visibility: (observacao === "" ? "hidden" : "visible"), transition: "all 0.5s ease" }} >
															Observação
												</Typography>
												<input
													type="text"
													placeholder="Observação"
													id="observacao"
													className={styles.inputText}
													value={observacao}
													onChange={e => setObservacao(e.target.value)}
												/>
											</Box>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<Grid
												container
												rowSpacing={1}
												columnSpacing={{ xs: 1, sm: 2, md: 3 }}
											>
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={styleInputFile}>
														<Item
															sx={{
																bgcolor: "inherit",
																border: "none",
																boxShadow: "none",
																display: "flex",
																alignItems: "start",
																justifyContent: "start",
																p: 0,
																my: 1,
																mx: 0,
															}}
														>
															<label
																htmlFor="fileTypeStockPrescription"
																className={styles.labelColorText}
															>
																<IosShareIcon />
																Anexar receituário
															</label>
														</Item>
														{initialReceituario === null && (<DropzoneArea
															id="fileTypeStockPrescription"
															Icon={PlagiarismIcon}
															getPreviewIcon={handlePreviewIcon}
															acceptedFiles={[
																"image/jpeg",
																"image/png",
																"image/bmp",
																"application/pdf",
															]}
															onChange={(newReceituarioFile) => {
																setReceituarioFile(newReceituarioFile);
															}}
															showPreviews={true}
															showPreviewsInDropzone={false}
															filesLimit={1}
															maxFileSize={5000000}
															previewText=" "
															dropzoneText="Faça upload do receituário JPG, PNG e PDF, somente"
															getFileLimitExceedMessage={(filesLimit) =>
																`Quantidade excedida: ${filesLimit} `
															}
															getFileAddedMessage={(fileName) =>
																`Imagen adicionada: ${fileName} `
															}
															getFileRemovedMessage={(fileName) =>
																`Imagen removida: ${fileName} `
															}
															showAlerts={["success", "info"]}
														/>)}
														{initialReceituario !== null && (<DropzoneArea
															id="fileTypeStockPrescription"
															Icon={PlagiarismIcon}
															getPreviewIcon={handlePreviewIcon}
															acceptedFiles={[
																"image/jpeg",
																"image/png",
																"image/bmp",
																"application/pdf",
															]}
															onChange={(newReceituarioFile) => {
																setReceituarioFile(newReceituarioFile);
															}}
															showPreviews={true}
															showPreviewsInDropzone={false}
															filesLimit={1}
															maxFileSize={5000000}
															previewText=" "
															dropzoneText="Faça upload do receituário JPG, PNG e PDF, somente"
															getFileLimitExceedMessage={(filesLimit) =>
																`Quantidade excedida: ${filesLimit} `
															}
															getFileAddedMessage={(fileName) =>
																`Imagen adicionada: ${fileName} `
															}
															getFileRemovedMessage={(fileName) =>
																`Imagen removida: ${fileName} `
															}
															showAlerts={["success", "info"]}
															initialFiles={[initialReceituario]}
														/>)}
													</Item>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<Grid
												container
												rowSpacing={1}
												columnSpacing={{ xs: 1, sm: 2, md: 3 }}
											>
												<Grid item xs={12} sm={12} md={12}>
													<Item sx={styleInputFile}>
														<Item
															sx={{
																bgcolor: "inherit",
																border: "none",
																boxShadow: "none",
																display: "flex",
																alignItems: "start",
																justifyContent: "start",
																p: 0,
																my: 1,
																mx: 0,
															}}
														>
															<label
																htmlFor="fileTypeStockInvoice"
																className={styles.labelColorText}
															>
																<IosShareIcon />
																Anexar nota fiscal do insumo
															</label>
														</Item>
														{initialNotaFiscal === null && (<DropzoneArea
															id="fileTypeStockInvoice"
															Icon={PlagiarismIcon}
															getPreviewIcon={handlePreviewIcon}
															acceptedFiles={[
																"image/jpeg",
																"image/png",
																"image/bmp",
																"application/pdf",
															]}
															onChange={(newNotaFiscalFile) => {
																setNotaFiscalFile(newNotaFiscalFile);
															}}
															showPreviews={true}
															showPreviewsInDropzone={false}
															filesLimit={1}
															maxFileSize={5000000}
															previewText=" "
															dropzoneText="Faça upload da nota fiscal JPG, PNG e PDF, somente"
															getFileLimitExceedMessage={(filesLimit) =>
																`Quantidade excedida: ${filesLimit} `
															}
															getFileAddedMessage={(fileName) =>
																`Imagen adicionada: ${fileName} `
															}
															getFileRemovedMessage={(fileName) =>
																`Imagen removida: ${fileName} `
															}
															showAlerts={["success", "info"]}
														/>)}
														{initialNotaFiscal !== null && (<DropzoneArea
															id="fileTypeStockInvoice"
															Icon={PlagiarismIcon}
															getPreviewIcon={handlePreviewIcon}
															acceptedFiles={[
																"image/jpeg",
																"image/png",
																"image/bmp",
																"application/pdf",
															]}
															onChange={(newNotaFiscalFile) => {
																setNotaFiscalFile(newNotaFiscalFile);
															}}
															showPreviews={true}
															showPreviewsInDropzone={false}
															filesLimit={1}
															maxFileSize={5000000}
															previewText=" "
															dropzoneText="Faça upload da nota fiscal JPG, PNG e PDF, somente"
															getFileLimitExceedMessage={(filesLimit) =>
																`Quantidade excedida: ${filesLimit} `
															}
															getFileAddedMessage={(fileName) =>
																`Imagen adicionada: ${fileName} `
															}
															getFileRemovedMessage={(fileName) =>
																`Imagen removida: ${fileName} `
															}
															showAlerts={["success", "info"]}
															initialFiles={[initialNotaFiscal]}
														/>)}
													</Item>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</RegisterArea>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}
export default StockCad;

import React, { useEffect, useState } from "react";
import InputSearch from "../../components/SearchHeader/inputSeach";
import TopMenu from "../../components/TopMenu";
import "../CommonStyle/style.css";

//import components
import Search from "../../components/SearchHeader";
import Table from "../../components/TableGenerator";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { captalizeFirstLetterEacheWord } from "../../utils/utils";
import { FarmService } from "../../service/farm_service";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//column
const columns =[
	{
		id: "name",
		label: "Cultivo",
	},
	{
		id: "packages",
		label: "Embalagens",
	},
	{
		id: "createdAt",
		label: "Data de criação",
	},
	{
		id: "barCode",
		label: "Código de barras",
	},

];

function Culture() {
	const [search, setSearch] = useState("");
	const [farms, setFarms] = useState([]);

	const farmService = new FarmService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");

		const farmParams = {
			page: 1,
			limit: 100000,
			sortBy: JSON.stringify([
				{
					cultivo: {
						cultura: "asc"
					}
				},
				{
					cultivo: {
						variedade: "asc"
					}
				}
			])
		};

		const farmsInProperty = await farmService.get(farmParams, propertyId);
		const farmsToShow = farmsInProperty.map((farm) => {
			return {
				id: farm.id,
				name: captalizeFirstLetterEacheWord(`${farm.cultivo.cultura} ${farm.cultivo?.variedade ?? ""}`),
				packages: farm.embalagens.map((item) => `${item.nome} (${item.quantidade} ${item.unidadeMedida.sigla})`),
				barCode: farm.codigoDeBarra ?? "",
				createdAt: new Date(farm.createdAt).toLocaleDateString("pt-BR")
			};
		});
		setFarms(farmsToShow);
	};

	const dataFilter = farms.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
	);
	function handleClick(e) {
		setSearch(e.target.value);
	}

	const handleDelete = async (id) => {
		await farmService.delete(id);
		await initData();
	};

	const custonOp = [
		{
			item: "Embalagens",
			func: function handleOpen(id) {
				window.location = `/culturas/Embalagens/${id}`;
			},
		},
	];

	return (
		<div>
			<TopMenu open={["register", "culture"]} help="culture" />
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<Search
									title="Culturas"
									href="/culturas/cadastroCultura"
									textButton="Nova Cultura"
								>
									<InputSearch func={handleClick} />
								</Search>
							</Item>
						</Grid>
						<Grid item xs={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<Table
									data={dataFilter}
									columns={columns}
									handleDelete={handleDelete}
									custonOpition={custonOp}
									editHref="/culturas/cadastroCultura?id="
									// onclickRow={"/culturas/Embalagens"}
								/>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default Culture;
